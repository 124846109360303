@import 'styles/_var';

.bannerSection {
  position: relative;
  display: block;
  background: linear-gradient(119deg, #232d40, #171e2d);
  margin: 0;
  padding: 10px 4.5% 10px;
}

.bannerHead {
  font-size: 3rem;
  z-index: 1;
  line-height: 58px;
  font-weight: bold;

  @media screen and (max-width: 1000px) {
    font-size: 2rem;
    line-height: normal;
  }
}

.heading {
  text-align: center;
  font-size: 2.3rem;
  line-height: normal;
  font-weight: bold;

  @media screen and (max-width: 1000px) {
    font-size: 1.8rem;
  }
}

.banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
  place-items: center;
  width: 100%;

  .contentImage {
    position: absolute;
    display: block;
    width: 35%;
    right: -20%;
    top: -5%;
    z-index: 0;
    margin-left: auto;
  }

  .bannerImage {
    position: relative;
    display: block;
    width: 100%;
    margin-right: -8vw;
    min-width: 500px;
  }

  .bannerContent {
    position: relative;
    // min-width: none;
    width: 100%;
    max-width: 600px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    & span {
      color: $gold;
    }

    & p {
      font-size: 18px;
      color: #acc9d8;
      opacity: 0.7;
    }

    .bannerBtn {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      button {
        position: relative;
        padding: 1.5rem;
        flex: 1;
        font-size: 16px;
        width: 100%;
        white-space: normal;
      }

      .mobileHoverBtn {
        position: relative !important;
        top: 0 !important;
      }

      .hoverCard {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        padding: 10px;
        background-color: #080c14;
        top: -100px;
        right: -190px;
        border-radius: 8px;
        z-index: 2;

        .qrImage {
          display: block;
          max-width: 150px;
          margin: auto;
        }

        & svg {
          border-radius: 8px;
          border: 2px solid #acc9d8;
        }

        & a {
          flex: 1;
          width: 100% !important;
        }

        .shareBtn {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 2px solid #acc9d8;
          background-color: #000;
          padding: 10px !important;
          color: #fff;
          border-radius: 8px;

          & svg {
            font-size: 25px;
            padding: 5px;
            background-color: #0e1525;
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);
            border-radius: 300px;
            border: none;
          }

          & span {
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }

    .bannerCounter {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: baseline;
      gap: 50px;

      .counterCard {
        position: relative;
        display: block;

        .counter {
          position: relative;
          display: block;
          width: 100%;
          font-size: 45px;
          font-weight: bold;
          color: #fff;
          opacity: 1;
          margin: 0;
          padding: 0;
        }

        .label {
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    .bannerImage {
      order: 1;
      width: 100%;
      min-width: unset;
      max-width: 375px;
      margin-right: unset;
      margin: auto;
    }

    .bannerContent {
      order: 2;
      margin: auto;

      & p {
        font-size: 16px;
      }

      .hoverContainer {
        position: relative;
        width: 100%;
        display: block;
        & button {
          display: block;
          padding: 20px !important;
          font-size: 16px !important;
        }
      }
      .hoverCard {
        right: unset !important;

        .qrImage {
          width: 100%;
          max-width: 200px !important;
        }
      }

      .bannerBtn {
        flex-direction: column;
        & button {
          padding: 1rem !important;
        }
      }
    }
  }
}

.downBtn {
  position: relative;
  display: block;
  margin-top: 40px;
  width: 100%;

  & a {
    position: relative;
    display: block;
    width: fit-content;
    font-size: 24px;
    margin: auto;
    text-align: center;
    background-color: #181f2f;
    color: #fff;
    border-radius: 300px;
    padding: 8px;
  }
}

.videoSection {
  position: relative;
  padding: 50px 4.5%;
  background-color: #181f2f;

  .container {
    position: relative;
    width: 100%;
    margin: auto;
  }

  .videoNav {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 60%;
    margin: auto;
    padding: 30px 0;

    & button {
      position: relative;
      display: block;
      font-size: 20px !important;
      padding: 15px !important;
      border-radius: 8px !important;
      text-align: center;
      background: transparent;
      width: 100%;
      height: 100%;

      &:hover {
        box-shadow: 0 0 10px #2c4474;
      }
    }

    .active {
      background: linear-gradient(106deg, #2c4474 0%, #152544 100%);
      border: none;
    }
  }
  .reactPlayer {
    padding-top: 60px;
    position: relative;
    width: 90%;
    margin: auto;
    height: 80vh;
    border-radius: 10px !important;
  }
  @media screen and (max-width: 1000px) {
    .reactPlayer {
      height: 60vh;
    }
    .videoNav {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 768px) {
    .reactPlayer {
      width: 100%;
      height: 50vh;
    }
    .videoNav {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & button {
        padding: 10px !important;
        font-size: 16px !important;
      }
    }
  }
}

.ourPlanSection {
  position: relative;
  padding: 50px 4.5%;
}

.whatSikkaSection {
  position: relative;
  padding: 50px 4.5%;
  background-color: #181f2f;

  & p {
    width: 70%;
    font-size: 18px;
    color: #acc9d8;
    // opacity: 0.7;
    text-align: center;
    margin: 30px auto;
  }

  .image {
    position: relative;
    display: block;
    margin: auto;
    width: 40%;
  }

  @media screen and (max-width: 1000px) {
    & p {
      width: 100%;
      font-size: 16px;
    }
    .image {
      width: 70%;
      max-width: 400px;
    }
  }
}

.sliderSection {
  position: relative;
  padding: 50px 4.5% 100px;

  & h2 {
    padding: 0 0 30px;
  }

  .sliderContainer {
    width: 90%;
    margin: 30px auto 0;
  }

  .slide {
    position: relative;
    // padding-bottom: 50px !important;
  }
  [class*='center'] {
    transform: scale(1.15) !important;
    // transition: all 1s ease !important;
  }
  [class*='slick-current'] {
    .card {
      background-color: #2d374b !important;
    }
  }

  .slickDots li button::before {
    position: relative;
    color: #fff !important;
    font-size: 10px !important;
  }

  .card {
    height: 380px !important;
    // height: 55vh !important;
    transition: background-color 0.3 ease !important;
    margin: 50px 0 !important;
    position: relative;
    max-width: 90%;
    border-radius: 8px;
    padding: 20px;
    display: flex !important;
    flex-direction: column;
    gap: 20px;
    background-color: #1f2534;
    box-shadow: 0px 3px 10px #00000038;
    border: none;

    .header {
      position: relative;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;

      .image {
        width: 80px;
        & img {
          border-radius: 300px;
        }
      }

      .headerContent {
        .title {
          font-size: 13px;
          opacity: 0.6;
        }
        .name {
          font-size: 16px;
        }
      }
    }
    .footer {
      .star {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & svg {
          font-size: 20px;
          fill: $gold;
        }
      }
      .content {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .card {
      height: 420px !important;
    }
  }

  @media screen and (max-width: 1250px) {
    .card {
      height: 450px !important;
    }
  }

  @media screen and (max-width: 1000px) {
    .card {
      height: 350px !important;
      margin: 50px auto !important;
    }
    .slide,
    .sliderContainer {
      position: relative;
      // overflow: hidden;
      width: 95%;
      margin: 30px auto 0;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 50px 0 100px;
    overflow: hidden;
    & h2 {
      padding: 0 4.5% 30px;
    }
    .card {
      // height: 450px !important;
      margin: 50px auto !important;
    }
    .slide,
    .sliderContainer {
      position: relative;
      width: 100%;
      margin: 30px auto 0;
      height: 100%;
    }
  }
  @media screen and (max-width: 650px) {
    .card {
      height: 400px !important;
    }
  }
  @media screen and (max-width: 450px) {
    .card {
      height: 450px !important;
    }
  }
}

.learnSection {
  position: relative;
  padding: 50px 4.5%;
  background-color: #181f2f;

  & h2 {
    margin-bottom: 30px;
  }

  & p {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: #acc9d8;

    @media screen and (max-width: 1000px) {
      font-size: 16px;
      line-height: normal;
    }
  }

  .content {
    width: 80%;
    margin: auto;
    text-align: center;

    & button {
      text-decoration: underline;
      font-size: 20px !important;
      margin: 30px 0 10px !important;
    }

    @media screen and (max-width: 1000px) {
      width: 90%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .hide {
    overflow: hidden;
    margin: 0;
    height: 0;
  }
  .show {
    overflow: hidden;
    margin: 0;
    height: 100%;
  }
}

.homeFooter {
  background-color: #181f2f;
  text-align: center;
  padding: 0px 4.5% 30px;
  position: relative;
  font-size: 38px;
  font-weight: bold;
  color: $gold;

  & p {
    opacity: 0.6;
  }

  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
}
