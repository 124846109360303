.PlayStoreButton {
  justify-self: flex-start;
  padding: 0px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  svg {
    font-size: 3rem;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 960px) {
    svg {
      font-size: 3rem;
    }
  }
}
